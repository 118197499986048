import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import TopSectionBgImage from "../components/Layout/TopSectionBgImage/TopSectionBgImage"
import translationHelper from "../i18n/helper"
import Layout from "../components/Layout/layout"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import SectionTitleSeperator from "../components/Misc/SectionTitleSeperator/SectionTitleSeperator"
import ServicesGrid from "../components/Services/ServicesGrid/servicesGrid"
import BoxedItem from "../components/Misc/BoxedItem/boxedItem"
import EmptySpaceSeperator from "../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"
import parse from "html-react-parser"
import GradientText from "../components/Misc/GradientText/gradientText"
import SectionSingleVerticalSeperator
  from "../components/Misc/SectionSingleVerticalSeperator/sectionSingleVerticalSeperator"
import GradientButton from "../components/Misc/GradientButton/gradientButton"
import EmployeeItem from "../components/Company/EmployeeItem/employeeItem"

const company = (props) => {

  const locale = props.pageContext.locale
  const employeeConfig = {
    dimitris: {
      name: {
        el: "Δημήτρης",
        en: "Dimitris",
      },
      position: "CEO",
      img: props.data.dimitris.childImageSharp.fluid,
      md: { span: 4, offset: 2 },
    },
    greg: {
      name: {
        el: "Γρηγόρης",
        en: "Gregory",
      },
      position: "CFO",
      img: props.data.greg.childImageSharp.fluid,
      md: 4,
    },
    elena: {
      name: {
        el: "Έλενα",
        en: "Helen"
      },
      position: "Project Manager",
      img: props.data.elena.childImageSharp.fluid,
      md: 3,
    },
    sin: {
      name: {
        el: "Θανάσης",
        en: "Thanasis"
      },
      position: "Full Stack Developer",
      img: props.data.thanasis.childImageSharp.fluid,
      md: 3,
    },
    stella: {
      name: {
        el: "Στέλλα",
        en: "Stella"
      },
      position: "Sales Manager",
      img: props.data.stella.childImageSharp.fluid,
      md: 3,
    },
    manolis: {
      name: {
        el: "Μανώλης",
        en: "Emanuel"
      },
      position: "Frontend Developer & UI Designer",
      img: props.data.manolis.childImageSharp.fluid,
      md: 3,
    },
    yamna: {
      name: {
        el: "Yamna",
        en: "Yamna"
      },
      position: "Commercial Manager",
      img: props.data.yamna.childImageSharp.fluid,
      md: 3,
    },
    bruno: {
      name: {
        el: "Bruno",
        en: "Bruno"
      },
      position: "Frontend Developer",
      img: props.data.bruno.childImageSharp.fluid,
      md: 3,
    },
    panagiotis: {
      name: {
        el: "Παναγιώτης",
        en: "Panagiotis"
      },
      position: "Full stack Developer",
      img: props.data.panagiotis.childImageSharp.fluid,
      md: 3,
    },
    nana: {
      name: {
        el: "Νανά",
        en: "Nana"
      },
      position: "Web Developer",
      img: props.data.nana.childImageSharp.fluid,
      md: 3,
    },
  }
  return (
    <Layout locale={props.pageContext.locale} path={props.path}>
      <SEO title={translationHelper[locale]["companyTitle"]}
           lang={props.pageContext.locale}
           description={translationHelper[locale]["companyDescription"]}
      />

      { /* Slider Section */}
      <TopSectionBgImage
        // heading="DIGITAL EXPERIENCES"
        customHeading={translationHelper[locale]["companySliderHeading"]}
        upperHeading={"<span class='colorLight'>MY<span class='mainColor'>BUSINESS</span>360</span>"}
        text={"<p class='colorLight'>" + translationHelper[locale]["companySliderText"] + "</p>"}
        fluid={props.data.bgImage.childImageSharp.fluid}
        locale={props.pageContext.locale}
        btnUrl={translationHelper[locale]["offerPageUrl"]}
        btnText={translationHelper[locale]["letStart"]}
      />

      <Container>

        <Row>
          <Col md={12}>
            <SectionTitleSeperator title={translationHelper[locale]["whoWeAreAndWhatWeDo"]}/>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <BoxedItem>
              <div className="p-5-percent">
                <h6 className="textFaded fontSans">{parse(translationHelper[locale]["companyDescriptionText"])}</h6>
                <EmptySpaceSeperator pixels={30}/>
                <GradientText
                  className="text-center w-100 f-s-30">{translationHelper[locale]["companyTextMoto"]}</GradientText>
              </div>
            </BoxedItem>
          </Col>
        </Row>
        <EmptySpaceSeperator pixels={30}/>
        <Row>
          <Col md={12}>
            <ServicesGrid showText={false} titleStyle={"gradient"} items={props.data.services.edges}/>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <SectionTitleSeperator title={translationHelper[locale]["meetTheTeam"]}/>
          </Col>
        </Row>
        <Row>
          {
            Object.keys(employeeConfig).map((employeeName) => {
              let employee = employeeConfig[employeeName]

              return <EmployeeItem key={employeeName} md={employee.md} imgFluid={employee.img} name={employee.name[locale]}
                                   position={employee.position}/>

            })
          }
        </Row>
        <SectionSingleVerticalSeperator/>
        <GradientButton linkStyle={{ display: "block" }} url={translationHelper[locale]["contactPageUrl"]}
                        btnText={translationHelper[locale]["contact"]}/>
        <EmptySpaceSeperator pixels={50}/>

      </Container>
    </Layout>
  )
}

export default company
export const companyPageQuery = graphql`
    query($locale: String!) {
        services: allWordpressPage(filter: {polylang_current_lang: {eq: $locale}, acf: {page_template: {eq: "services"}}}) {
            edges {
                node {
                    id
                    slug
                    path
                    content
                    title
                    template
                    excerpt
                    acf {

                        page_template
                        short_description
                    }
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
        }
        bgImage: file(relativePath: {eq: "mybusiness360-bg.jpg"}) {
            childImageSharp {
                fluid(maxWidth:4200) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
        dimitris: file(relativePath: {eq: "employees/Dimitris-martzelos-tel.png"}) {
            childImageSharp {
                fluid(maxWidth:600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        greg: file(relativePath: {eq: "employees/Greg-Lanaras-tel.png"}) {
            childImageSharp {
                fluid(maxWidth:600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        elena: file(relativePath: {eq: "employees/Elena-Michailidou-tel.png"}) {
            childImageSharp {
                fluid(maxWidth:600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        thanasis: file(relativePath: {eq: "employees/Thanasis-kontokostas-tel.png"}) {
            childImageSharp {
                fluid(maxWidth:600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        stella: file(relativePath: {eq: "employees/Stella-Papanikolaou-tel.png"}) {
            childImageSharp {
                fluid(maxWidth:600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        manolis: file(relativePath: {eq: "employees/Emmanouil-Koumortzis-tel.png"}) {
            childImageSharp {
                fluid(maxWidth:600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        nana: file(relativePath: {eq: "employees/Nana-2.png"}) {
            childImageSharp {
                fluid(maxWidth:600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        panagiotis: file(relativePath: {eq: "employees/Papadimitriou-Panagiotis-tel.png"}) {
            childImageSharp {
                fluid(maxWidth:600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        bruno: file(relativePath: {eq: "employees/Bruno-3.png"}) {
            childImageSharp {
                fluid(maxWidth:600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        yamna: file(relativePath: {eq: "employees/Yamna-Tagrerout.png"}) {
            childImageSharp {
                fluid(maxWidth:600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }

    }

`