import React from "react"
import BoxedItem from "../../Misc/BoxedItem/boxedItem"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import classes from "./employeeItem.module.scss"
import Col from "react-bootstrap/Col"

const employeeItem = (props) => {
  return (
    <Col md={props.md}>
    <BoxedItem>
      <Img alt={`${props.name} ${props.position} at Mybusiness360`} fluid={props.imgFluid}/>
      <h4 className={classes.EmployeeName}>{props.name}</h4>
      <p className={classes.EmployeePosition}>{props.position}</p>
    </BoxedItem>
    </Col>
  )
}

employeeItem.propTypes = {
  imgFluid: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  // md:PropTypes.isRequired
}
export default employeeItem
